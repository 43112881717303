import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

// Allow each page to pass an image.  If not, use the sitemetadata.
export default function SEO({ children, location, description, image, title }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
        }
      }
    }
  `);
  return (
    <Helmet titleTemplate={`%s - ${site.siteMetadata.title}`}>
      <title>{title}</title>
      <html lang="en" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      {/* Open graph stuff */}
      {location && <meta property="og:url" content={location.href} />}
      <meta
        property="og:image"
        content={`https://smonos.com/${image || site.siteMetadata.image}`}
      />
      <meta
        property="og:title"
        content={title || site.siteMetadata.title}
        key="ogtitle"
      />
      <meta
        propery="og:site_name"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta
        property="og:description"
        content={site.siteMetadata.description}
        key="ogdesc"
      />
      {children}
    </Helmet>
  );
}
